import React, { useState, useEffect } from 'react'        
import EbcLogo from "../ebc_logo.png";
import EbcLogoMobile from "../ebc_logo_sm.png";
import { Box } from '@mui/material';

export default function LogoImage() {
  const [largeScreen, setLargeScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
        setLargeScreen(window.innerWidth > 550);
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
    return(
        <Box component="img" src={largeScreen ? EbcLogo : EbcLogoMobile} sx={{ maxHeight: {xs: '2.5rem', lg:'2.5rem'} }} />
    )    
}