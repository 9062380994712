import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useLayerVisibilityStore } from "../store/layerStore";
import { Box, Button, IconButton, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body1,
  padding: theme.spacing(-2),
  textAlign: 'center',
  color: theme.palette.text.primary,
  fontFamily: 'Bebas Neue',
}));
export const NotificationArea = ({containerHeight, margin}: {containerHeight: number, margin: number}) => {
//export default function NotificationArea(props: any) {
  const [open, setOpen] = React.useState(false);
  const { bs11, bs10, toggle, map, overlay, toggleBs11, toggleBs10, bs11Overlay, bs10Overlay, selected, setSelected, searchPlace, setSearchPlace, setEd, ed, edInfo, setEdInfo } = useLayerVisibilityStore();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway' || reason === 'timeout') {
      return;
    }

    // setOpen(false);
    setEdInfo(null);
  };

  const action = (
    <React.Fragment >
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        edge="end"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const message_stack = (
    <Stack direction="column">
      <Item><Typography >Your electoral district for the 2024 Provincial Election will be:</Typography></Item>
      <Item><Typography variant='h5'>{edInfo?.edName} ({edInfo?.edAbbr})</Typography></Item>
      <Item><Typography >New electoral districts will come into effect when the next provincial election is called on September 21, 2024.
      </Typography></Item>
    </Stack>
  )

  const message = (
    // <Box alignContent={'center'} alignItems={'center'}>
    <React.Fragment >
      <Typography>Your electoral district for the 2024 Provincial Election will be:</Typography>
      <Typography variant='h5'>{edInfo?.edName} ({edInfo?.edAbbr})</Typography>
      <Typography >New electoral districts will come into effect when the next provincial election is called on September 21, 2024.</Typography>
    </React.Fragment>
    // </Box>
  )
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  }));

  return (
    // <Box>
    <Paper elevation={0} sx={{ margin: `${margin}px` }}>
      {/* <Stack direction="row" alignItems="flex-start" justifyContent="center" >
        <Stack direction="column" alignItems="center" justifyContent="space-around" sx={{height: `calc(${containerHeight}px - 4px - 4px)`, ml: 'auto'}} > 
          <Typography align='center'>Your electoral district for the 2024 Provincial Election will be:</Typography>
          <Typography variant='h5' align='center' sx={{fontFamily: 'Bebas Neue'}}>{edInfo?.edName} ({edInfo?.edAbbr})</Typography>
          <Typography align='center'>New electoral districts will come into effect when the next provincial election is called on September 21, 2024.</Typography>
        </Stack>
        <IconButton size="large" edge="end" color="default" aria-label="help" onClick={handleClose} sx={{mr:'8px', ml: 'auto'}}>
          <CancelIcon/>
        </IconButton>
      </Stack> */}
     
      {/* <Stack direction="row" alignItems="flex-start" justifyContent="center" > */}
        

        <Stack direction="column" alignItems="center" 
          justifyContent="space-evenly" 
          sx={{ height: `calc(${containerHeight}px - ${margin}px - ${margin}px)`, ml: 'auto', backgroundColor: '#565656'}} >
        {/* <IconButton size="small" edge="end" color="default" aria-label="help" onClick={handleClose} sx={{ml: 'auto', mr: '0px', mt: '0px'}}>
          <CancelIcon/>
        </IconButton>  */}
          <Typography align='center' sx={{color:'white'}}>Your electoral district for the 2024 Provincial Election will be:</Typography>
          <Typography variant='h5' align='center' sx={{fontFamily: 'Bebas Neue', color:'white'}}>{edInfo?.edName} ({edInfo?.edAbbr})</Typography>
          {/* <Typography align='center' sx={{color:'white'}}>New electoral districts will come into effect when the next provincial election is called on September 21, 2024.</Typography> */}
        </Stack>
      {/* </Stack>       */}
    </Paper>
    // </Box>
  );
}