import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from "@mui/icons-material/Search";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import styled from 'styled-components';
import { InputAdornment, alpha } from '@mui/material';
import axios from 'axios';
import { useLayerVisibilityStore } from '../../store/layerStore';
import { bs11EdBounds as edBounds, bs11EdInfo as edInfo } from '../GoogleMaps/utils/utils';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
//const GOOGLE_MAPS_API_KEY = 'AIzaSyDKnNJeo0PnpWTTQ3DtMSPQrr6dQdxIUWc';//'AIzaSyC3aviU6KHXAjoSnxcw6qbOhjnFctbxPkE';
const GOOGLE_MAPS_API_KEY = 'AIzaSyAjxt6nhZhlXOZvKqoFRVDbVzxa_GL_Jg4';

const prepareSearchQuery = (query: string) => {
    const url__ = `http://api.tvmaze.com/search/shows?q=${query}`;
    const url_ = `https://wtv-address-search.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=xndNyuc8J3wOR0lFw6BnO8QACFxhdUXOtF8ZfqvkAnAzSeAcDSOD`;
    const url = `https://wtv-addr-ed.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=l4VEL984tkJn6KEIrLJRUtCrTQdAXYIhMQM685emrYAzSeAVjXLM`;
    const SEARCH_URL___ = `https://wtv-ed11-scored-pktest.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=mT98zbNzzoFCNxkGc5qmQRsV8ws5cY51NSAB10sUxOAzSeDO7HG2`;
    const SEARCH_URL__ = `https://wtv-address-b11-scored-standbldgnum.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=HOxBb9mHWgb0G31M3Peg3P424Qbw8pVH4QJDlddtQLAzSeDW1cUG`;
    const SEARCH_URL_ = `https://wtv-address-b11.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=RC0EhoQkrUcL7MKPFrBfTZLiTSFJe6ujTJbpMOOAbTAzSeDUxPHK`;
    // const SEARCH_URL = `https://wtv-address-b11.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=VCHUfxeHttzb71vzzwSKcpAvLoWExmHsGvXpOLUYMQAzSeCoOgwa`;

    const SEARCH_URL_EBC = `https://my-district-bs11-b.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=pJU9er3NmwpbYlN9DuCrvWxWbQqNdXZwvhgfIq18FXAzSeDrVdIp`;
    // const SEARCH_URL = `https://my-district-bs11-b.search.windows.net/indexes/address-search-bs11-index/docs?api-version=2023-10-01-Preview&search=${query}*&$top=10&api-key=pJU9er3NmwpbYlN9DuCrvWxWbQqNdXZwvhgfIq18FXAzSeDrVdIp`;
    // const SEARCH_URL = `https://my-district-bs11-b.search.windows.net/indexes/address-search-bs11-index/docs?api-version=2023-10-01-Preview&search=${query}*&$top=10&api-key=pJU9er3NmwpbYlN9DuCrvWxWbQqNdXZwvhgfIq18FXAzSeDrVdIp`;
    //const SEARCH_URL =     `https://wtv-address-b11.search.windows.net/indexes/address-search-bs11-index/docs?api-version=2023-11-01&search=${query}*&$top=10&api-key=VCHUfxeHttzb71vzzwSKcpAvLoWExmHsGvXpOLUYMQAzSeCoOgwa`;
    //const SEARCH_URL =     `https://wtv-address-b11.search.windows.net/indexes/address-search-bs11-index/docs?api-version=2023-11-01&search=${query}*&$top=10&api-key=VCHUfxeHttzb71vzzwSKcpAvLoWExmHsGvXpOLUYMQAzSeCoOgwa`;
    //new forte const SEARCH_URL =     `https://wtv-address-b11.search.windows.net/indexes/my-district-search-bs11-index/docs?api-version=2023-10-01-Preview&search=${query}*&$top=10&api-key=VCHUfxeHttzb71vzzwSKcpAvLoWExmHsGvXpOLUYMQAzSeCoOgwa`;
    const SEARCH_URL = `https://my-district-bs11-b.search.windows.net/indexes/my-district-search-bs11-index/docs?api-version=2023-10-01-Preview&search=${query}*&$top=10&api-key=pJU9er3NmwpbYlN9DuCrvWxWbQqNdXZwvhgfIq18FXAzSeDrVdIp`;

    return encodeURI(SEARCH_URL);
  };

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  result?: any;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    color: "#fff",
    background: "rgba(255, 255, 255, 0.15)",
    borderRadius: "4px",
    width: "100%",
    "& input": {
      color: "#fff !important",
      "&::placeholder": {
        color: 'white',
        opacity: 1.0,
      }
    },
    "& fieldset": {
      borderWidth: "0px",
      "& fieldset:hover, & fieldset:focus, & fieldset:active": {
        borderWidth: "0px"
      },
      "& .MuiInputBase-input": {
        padding: theme.spacing(2, 1, 1, 2),
        transition: theme.transitions.create("width"),
        color: "#fff",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          width: "12ch",
          "&:focus": {
            width: "20ch"
          }
        }
      }
    }
  }));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.125),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: { // was sm but md resized to 100% width sooner
      //marginLeft: theme.spacing(1),
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto', // was 0px, set to auto to force larger search bar to center in appbar
    },    
  }));

export default function AzureAutocomplete(props:any) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [initialized, setInitialized] = React.useState(false);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);
  const { setHighlightInfo, searchPlace, setSearchPlace, setEdInfo, defaultPlace, } = useLayerVisibilityStore();
  
//   console.log(`*** Start AzureAutocomplete`,initialized,defaultPlace);

  if (typeof window !== 'undefined' && !loaded.current) {
    // if (!document.querySelector('#google-maps')) {
    //   loadScript(
    //     `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
    //     document.querySelector('head'),
    //     'google-maps',
    //   );
    // }
    // console.log("AzureAutocomplete (1)",initialized,searchPlace);
    loaded.current = true;
  }

  const fetchAzure = React.useMemo(
    () =>
        debounce(
            async (
                request: { input: string },
                callback: (results?: readonly PlaceType[]) => void,
            ) => {
                const response = await axios.get(prepareSearchQuery(request.input),{}).catch((err:any) => {
                    console.log("Error: ", err);
                });
                if (response) {
                    console.log("Response: ", response.data);
                    // if (response.data && response.data.length === 0) setNoTvShows(true);
                    callback(response.data.value.map((f:any) =>  ({
                        description: f.street_addr,
                        result: f, 
                        structured_formatting: {
                            main_text: f.street_addr,
                            secondary_text: `${f.ed_name} (${f.ed_abbr})`,
                        }
                    }) ));
                    // setTvShows(response.data.value);
                  }
            }
        ),[]
  );

  const fetch_ = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  function handleLocationChange(p: PlaceType | null) {
    if (p && p.result) {
        // map?.setCenter({ lat: p?.result.y, lng: p?.result.x });
        //console.log(p.result);

        // @CT commented 2024Jan11
        // const bounds = edBounds(p.result.ed_abbr);
        // setBounds(bounds);
        // setEd(p.result.ed_abbr);
        // // map?.fitBounds(bounds);
        setSearchPlace(p);
        setHighlightInfo(undefined);
        const info = edInfo(p.result.ed_abbr);
        setEdInfo(info);
    } else if (p === null) {
      setEdInfo(null);
      setSearchPlace(p);
    }
  }

  React.useEffect(() => {
    let active = true;

    // if (!autocompleteService.current && (window as any).google) {
    //   autocompleteService.current = new (
    //     window as any
    //   ).google.maps.places.AutocompleteService();
    // }
    // if (!autocompleteService.current) {
    //   return undefined;
    // }
    if(!initialized && defaultPlace) {
        setInputValue(defaultPlace?.description);
        setValue(defaultPlace);
        setInitialized(true);
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    // fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
    //   if (active) {
    //     let newOptions: readonly PlaceType[] = [];

    //     if (value) {
    //       newOptions = [value];
    //     }

    //     if (results) {
    //       newOptions = [...newOptions, ...results];
    //     }

    //     setOptions(newOptions);
    //   }
    // });

    fetchAzure({ input: inputValue }, (results?: readonly PlaceType[]) => {
        if (active) {
            let newOptions: readonly PlaceType[] = [];
    
            if (value) {
              newOptions = [value];
            }
    
            if (results) {
              newOptions = [...newOptions, ...results];
            }
    
            setOptions(newOptions);
          }        
    });



    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, fetchAzure, defaultPlace]);

  return (
    <StyledAutocomplete
      id="ed-address-search"
      sx={{ 
        position: 'relative', 
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        // @CT commented 2024Jan11
        // setSearchPlace(newValue);
        handleLocationChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    //   renderInput={(params) => (
    //     <TextField {...params} label="Add a location" fullWidth />
    //   )}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder="Enter your address to find your district..."
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  style={{ color: "white", opacity: 1.0, marginLeft: "8px" }}
                />
              </InputAdornment>
            )
          }}
        />
      )}    
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
