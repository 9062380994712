import { Button, IconButton, Paper } from '@mui/material';
import {ControlPosition, MapControl, useMap} from '@vis.gl/react-google-maps';
import React from 'react';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useLayerVisibilityStore } from "../../store/layerStore";

type CustomZoomControlProps = {
  controlPosition: ControlPosition;
  zoom: number;
  onZoomChange: (zoom: number) => void;
};

export const CustomZoomControl = ({
  controlPosition,
  zoom,
  onZoomChange
}: CustomZoomControlProps) => {
    const { setMarker } = useLayerVisibilityStore();
  return (
    <MapControl position={controlPosition}>
      <div
        style={{
          margin: '10px',
          padding: '1em',
          background: 'rgba(255,255,255,0.4)',
          display: 'flex',
          flexFlow: 'column nowrap'
        }}>
        <label htmlFor={'zoom'}>This is a custom zoom control!</label>
        <input
          id={'zoom'}
          type={'range'}
          min={1}
          max={18}
          step={'any'}
          value={zoom}
          onChange={ev => onZoomChange(ev.target.valueAsNumber)}
        />
      </div>
    </MapControl>
  );
};

type ZoomPositionControlProps = {
    controlPosition: ControlPosition;
};

function zoomLocation(map: google.maps.Map | null) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((p) => {
            // map?.panTo({lat: p.coords.latitude, lng: p.coords.longitude});
            // const bnd = new google.maps.LatLngBounds()
            map?.fitBounds({south: (p.coords.latitude - 0.01075), west: (p.coords.longitude - 0.01075), north: (p.coords.latitude + 0.01075), east: (p.coords.longitude + 0.01075),})
        });
    }
}

export const ZoomPositionControl = ({
    controlPosition,
}:ZoomPositionControlProps) => {
    const { setMarker } = useLayerVisibilityStore();
    const map = useMap();

    function zoom() {
        console.log('> Zoom');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((p) => {
                map?.fitBounds({south: (p.coords.latitude - 0.01075), west: (p.coords.longitude - 0.01075), north: (p.coords.latitude + 0.01075), east: (p.coords.longitude + 0.01075),})
                setMarker({lat: p.coords.latitude, lng: p.coords.longitude});
            });
        }
    }



    return (
    <MapControl position={controlPosition}>
        <Paper 
            elevation={0} 
            sx={{
                mr:"10px", 
                width:"40px", 
                height:"40px", 
                borderRadius:"2px"
            }}>
            <IconButton size="medium" onClick={ () => {zoom()} }>
                <MyLocationIcon 
                    sx={{
                        color:"#666666"
                    }} />
            </IconButton>
        </Paper>
    </MapControl>
    );
};