import React, { useEffect, useRef, useState } from 'react';
import { APIProvider, ControlPosition, InfoWindow, Map, MapControl, Marker, useMap } from '@vis.gl/react-google-maps';
import { Bs10DeckGlOverlay, Bs11DeckGlOverlay } from './DeckGlOverlay';
import { RegionListener, SearchListener } from './MapInfluence';
import { useLayerVisibilityStore } from '../../store/layerStore';
import { Typography } from '@mui/material';
import { CustomZoomControl, ZoomPositionControl } from './CustomControls';
import MapMarker from './MapMarker';
import { PlaceType } from '../Search/AzureAutocomplete';
import { bs11EdInfo } from './utils/utils';

// const API_KEY = 'AIzaSyDKnNJeo0PnpWTTQ3DtMSPQrr6dQdxIUWc';
const API_KEY = 'AIzaSyAjxt6nhZhlXOZvKqoFRVDbVzxa_GL_Jg4';
const DEFAULT_CENTER = { lat: 53.7267, lng: -127.6476 };
const DEFAULT_ZOOM = 6; //4;

export default function GoogleMapsContainer(props:any) {
    const isInitialMount = useRef(true);
    const { bs11, bs10, searchPlace, setSearchPlace, setEdInfo, highlightInfo, setHighlightInfo, showInfoWindow, setShowInfoWindow, marker, markerLat, markerLng } = useLayerVisibilityStore();

    const closeInfoWindow = () => {setHighlightInfo(undefined); setShowInfoWindow(false)};
    
    useEffect(() => {
        console.log(`GoogleMaps useEffect ${isInitialMount.current}`, searchPlace);
        if (isInitialMount.current) {
            isInitialMount.current = false;          
        }
    });
    const mapStyleOptions = [
        {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "on" }],
        },
    ]

return (
    <APIProvider apiKey={API_KEY}>
        <Map
            zoom={ DEFAULT_ZOOM }
            center={ DEFAULT_CENTER }
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            draggableCursor={'default'}
            zoomControl={true}
            style={{ width: "100vw", height: `calc(100vh - ${props.containerHeight}px)`, transition: 'height 0.1s' }}
            styles={mapStyleOptions}
        >
            <ZoomPositionControl controlPosition={ControlPosition.RIGHT_BOTTOM} />
            {showInfoWindow && highlightInfo && ((highlightInfo.bs === '11' && bs11) || (highlightInfo.bs === '10' && bs10 && !bs11)) && (
                <InfoWindow zIndex={1} position={{ lat: markerLat, lng: markerLng }} onCloseClick={closeInfoWindow} >
                    <Typography variant='body2' sx={{mt: "5px", fontWeight:"400"  }}>{highlightInfo.bs === '11' ? '2024 BOUNDARY' : '2020 BOUNDARY'}</Typography>
                    <Typography variant='h6' sx={{fontFamily: "Bebas Neue", color: highlightInfo.bs === '11' ? "#db282e" : "#565656", }}>{`${highlightInfo.edName} (${highlightInfo.edAbbr})`}</Typography>   
                </InfoWindow>
            )}
            <Bs10DeckGlOverlay />
            <Bs11DeckGlOverlay />
            <SearchListener />
            <RegionListener />
            { marker && <MapMarker /> }
            {searchPlace && 
                <Marker position={{lat: searchPlace?.result.y, lng: searchPlace?.result.x }} />
            }
        </Map>
    </APIProvider>
)
};