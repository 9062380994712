import { create } from 'zustand';
import { GoogleMapsOverlay } from '@deck.gl/google-maps/typed'
import { PickingInfo } from 'deck.gl/typed';
import { PlaceType } from '../components/Search/AzureAutocomplete';
import { backdropClasses } from '@mui/material';

export type ElectoralDistrictInfo = {
    edName: string,
    edAbbr: string,
    area: string,
    groupName: string,
    minLng: number,
    maxLng: number,
    minLat: number,
    maxLat: number,
    bounds: google.maps.LatLngBoundsLiteral,
}

type LayerVisibilityStore = {
    bs11: boolean,
    bs10: boolean,
    map?: google.maps.Map,
    overlay?: GoogleMapsOverlay,
    bs11Overlay?: GoogleMapsOverlay,
    bs10Overlay?: GoogleMapsOverlay,
    positionOverlay?: GoogleMapsOverlay,
    selected?: PickingInfo,
    searchPlace?: PlaceType | null,
    ed?: string,
    bounds?: google.maps.LatLngBoundsLiteral,
    edInfo?: ElectoralDistrictInfo | null,
    region?: string,
    marker?: google.maps.LatLngLiteral,
    markerLat: number,
    markerLng: number,
    highlightInfo?: any,
    bs11HighlightInfo?: any,
    bs10HighlightInfo?: any,
    showInfoWindow: boolean,
    defaultPlace: PlaceType | null,
    toggle: () => void,
    toggleBs11: () => void,
    toggleBs10: () => void,
    setMap: (m: google.maps.Map) => void,
    setBs11Overlay: (o: GoogleMapsOverlay) => void,
    setBs10Overlay: (o: GoogleMapsOverlay) => void,
    setPositionOverlay: (o: GoogleMapsOverlay) => void,    
    setSelected: (sel: PickingInfo) => void,
    setSearchPlace: (s: PlaceType | null) => void,
    setEd: (sel: string) => void,
    setBounds: (b: google.maps.LatLngBoundsLiteral) => void,
    setEdInfo: (e: ElectoralDistrictInfo | null) => void,
    setRegion: (region: string) => void,
    setMarker: (m: google.maps.LatLngLiteral) => void,
    setMarkerLat: (l: number) => void,
    setMarkerLng: (l: number) => void,
    setHighlightInfo: (h: any) => void,
    setBs11HighlightInfo: (h: any) => void,
    setBs10HighlightInfo: (h: any) => void,        
    setShowInfoWindow: (b: any) => void,
    setDefaultPlace: (s: PlaceType | null) => void,
}

export const useLayerVisibilityStore = create<LayerVisibilityStore>((set) => ({
    bs10: false,
    bs11: true,
    map: undefined,
    overlay: undefined,
    bs11Overlay: undefined,
    bs10Overlay: undefined,
    positionOverlay: undefined,
    selected: undefined,
    search: undefined,
    ed: undefined,
    bounds: undefined,
    edInfo: undefined,
    region: undefined,
    marker: undefined,
    markerLat: 49.3994,
    markerLng: -123.5573,
    highlightInfo: undefined,
    bs11HighlightInfo: undefined,
    bs10HighlightInfo: undefined,
    showInfoWindow: false,
    defaultPlace: null,
    toggle: () => set((state) => ({ bs11: !state.bs11 })),
    toggleBs11: () => set((state) => ({ bs11: !state.bs11 })),
    toggleBs10: () => set((state) => ({ bs10: !state.bs10 })),
    setMap: (m) => set((state) => ({ map: m })),
    setBs11Overlay: (o) => set((state) => ({bs11Overlay: o})),
    setBs10Overlay: (o) => set((state) => ({bs10Overlay: o})),
    setPositionOverlay: (o) => set((state) => ({positionOverlay: o})),
    setSelected: (sel) => set((state) => ({ selected: sel })),
    setSearchPlace: (sp) => set((state) => ({ searchPlace: sp })),
    setEd: (ed) => set((state) => ({ ed: ed })),
    setBounds: (bnd) => set((state) => ({ bounds: bnd })),
    setEdInfo: (e) => set((state) => ({ edInfo: e})),
    setRegion: (r) => set((state) => ({ region: r })),
    setMarker: (m) => set((state) => ({ marker: m })),
    setMarkerLat: (l) => set((state) => ({ markerLat: l })),
    setMarkerLng: (l) => set((state) => ({ markerLng: l })),
    setHighlightInfo: (h) => set((state) => ({ highlightInfo: h })),
    setBs11HighlightInfo: (h) => set((state) => ({ bs11HighlightInfo: h })),
    setBs10HighlightInfo: (h) => set((state) => ({ bs10HighlightInfo: h })),
    setShowInfoWindow: (b) => set((state) => ({showInfoWindow: b})),
    setDefaultPlace: (sp) => set((state) => ({ defaultPlace: sp })),
}))