import React, { useEffect, useRef } from "react";
import { responsiveFontSizes } from "@mui/material/styles";
import { useLayerVisibilityStore } from "./store/layerStore";
// import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { AppBar, Box, Card, CardContent, CardHeader, Divider, Drawer, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemText, Skeleton, Stack, Theme, Toolbar, Typography, createTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutline from '@mui/icons-material/HelpOutlineSharp';
import ListItemIcon from '@mui/material/ListItemIcon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkIcon from '@mui/icons-material/Link';
import MailIcon from '@mui/icons-material/Mail';
import MapIcon from '@mui/icons-material/Map';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ThemeProvider } from "styled-components";
import '@fontsource/bebas-neue';
// import { updateLayerVisibility } from "./components/GoogleMaps/GoogleMaps";
import { PickingInfo } from "deck.gl/typed";
import * as turf  from "@turf/turf";
import { Android12Switch } from "./components/Android12Switch";
import AzureAutocomplete, { PlaceType } from "./components/Search/AzureAutocomplete";
import {NotificationArea} from "./components/NotificationArea";
import LogoImage from "./components/LogoImage";
// import {Marker} from '@googlemaps/adv-markers-utils';
import { bs11EdInfo, bs11RegionBounds } from "./components/GoogleMaps/utils/utils";
import GoogleMapsContainer from "./components/GoogleMaps/GoogleMapsContainer";
import { useMap } from "@vis.gl/react-google-maps";

//const API_KEY = 'AIzaSyDKnNJeo0PnpWTTQ3DtMSPQrr6dQdxIUWc';
const API_KEY = 'AIzaSyAjxt6nhZhlXOZvKqoFRVDbVzxa_GL_Jg4';
const GEOJSON_URL = 'https://ebcwtvgeoserver.canadacentral.cloudapp.azure.com/geoserver/wtv/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wtv%3AEBC_ELECTORAL_DISTS_BS11&maxFeatures=90&outputFormat=application%2Fjson&srsName=EPSG:4326';

const drawerWidth = '485px';
const baseHeaderHeight = 64; // <Toolbar /> default height
const baseSearchHeight = 64; // <Toolbar /> default height
const baseInfoHeight = 100;  // default height will be reduced by top and bottom margin
const baseInfoHeightSm = 120;
const baseFooterHeight = 56;

enum DrawerContentType {
  Menu = 1,
  Help,
}

const renderDrawerContent = (contentType: DrawerContentType) => {
  switch (contentType) {
  case DrawerContentType.Menu:
    return (
    <Box sx={{ overflow: 'scroll', flexGrow: 1, p: 1, fontFamily:'Bebas Neue' }}>
        <Typography paragraph sx={{pl: '10px'}}>
            Enter your address in the search bar to find your electoral district for the 2024 Provincial General Election. 
            The election is scheduled for Saturday, October 19, 2024. New electoral districts will come into effect when 
            the election is called on Saturday, September 21, 2024.
        </Typography>
        <Typography variant="h5" paragraph sx={{pl: '10px'}}>Why are electoral districts changing?</Typography>
        <Typography paragraph sx={{pl: '10px'}}>
            In 2022, an independent, non-partisan boundaries commission made recommendations to change B.C.'s 
            provincial electoral districts. In 2023, the Legislative Assembly accepted all of the commission's 
            recommendations. This included adding six new electoral districts for the next election, increasing 
            the total number of districts from 87 to 93.
        </Typography>    
        <Divider />
        <List dense >
            <ListItem key='contact-us'>
                <Divider />
                <ListItemText  primary='Contact Us' />
            </ListItem>
            <ListItem key='call' component="a" href="tel:+1-800-661-8683">
                <ListItemButton>
                    <ListItemIcon>
                        <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText primary='Call Elections BC 1-800-661-8683' />
                </ListItemButton>                
            </ListItem>
            <ListItem key='mail' component="a" href="mailto:electionsbc@elections.bc.ca" target="_blank">
                <ListItemButton>
                    <ListItemIcon>
                        <MailIcon />
                    </ListItemIcon>
                    <ListItemText primary='electionsbc@elections.bc.ca' />
                </ListItemButton>                
            </ListItem>
            <ListItem key='link' component="a" href="https://elections.bc.ca" target="_blank">
                <ListItemButton>
                    <ListItemIcon>
                        <LinkIcon />
                    </ListItemIcon>
                    <ListItemText primary='elections.bc.ca' />
                </ListItemButton>                
            </ListItem>
            <ListItem key='learn-more'>
                <Divider />
                <ListItemText  primary='Learn More' />
            </ListItem>
            <ListItem key='privacy' component="a" href="https://elections.bc.ca/privacy/" target="_blank">
                <ListItemButton>
                    <ListItemIcon>
                        <LockPersonIcon />
                    </ListItemIcon>
                    <ListItemText primary='Privacy Information' />
                </ListItemButton>                
            </ListItem>
            <ListItem key='ed' component="a" href="https://elections.bc.ca/resources/maps/2024-provincial-election-maps/" target="_blank">
                <ListItemButton>
                    <ListItemIcon>
                        <MapIcon />
                    </ListItemIcon>
                    <ListItemText primary='2024 Provincial Election Maps' />
                </ListItemButton>                
            </ListItem>                
        </List>
    </Box>
    );
  case DrawerContentType.Help:
    return (
      <Box sx={{ overflow: 'scroll', flexGrow: 1, p: 2, }}>
        <List sx={{ listStyle: "decimal", pl: 2, fontFamily: "Roboto" }}>
            <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Enter your address into the search bar"/>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Select your address from the dropdown menu"/>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="The map will zoom to the boundaries of your electoral district and display your district name at the bottom of the screen"/>
            </ListItem>                        
        </List>
        <Typography>
            Do not include a unit number in your street address
        </Typography>
        <Typography paragraph>
            (e.g. 1112 Fort St, Victoria — <b>not</b> 100-1112 Fort St, Victoria).
        </Typography>        
        <Typography paragraph>
            If your address does not appear in the dropdown menu, please contact us for assistance using the links below.
        </Typography>        
        <Typography>
            You can also view and print PDF maps of individual electoral districts on our website.
        </Typography>
        <List dense >
            <ListItem key='call' component="a" href="https://elections.bc.ca/resources/maps/2024-provincial-election-maps/" target="_blank">
                <ListItemButton>
                    <ListItemIcon>
                        <MapIcon />
                    </ListItemIcon>
                    <ListItemText primary='View and print PDF maps' />
                </ListItemButton>                
            </ListItem>
        </List>
      <Divider />

      <Typography sx={{pt:"18px"}}>
        Questions?
      </Typography>      
        <List dense>
            {/* <ListItem key='questions' >
                <ListItemText  primary='Questions' />
            </ListItem>             */}
            <ListItem key='call' component="a" href="tel:+1-800-661-8683">
                <ListItemButton>
                    <ListItemIcon>
                        <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText primary='Call Elections BC 1-800-661-8683' />
                </ListItemButton>                
            </ListItem>
            <ListItem key='mail' component="a" href="mailto:electionsbc@elections.bc.ca" target="_blank">
                <ListItemButton>
                    <ListItemIcon>
                        <MailIcon />
                    </ListItemIcon>
                    <ListItemText primary='electionsbc@elections.bc.ca' />
                </ListItemButton>                
            </ListItem>
            <ListItem key='link' component="a" href="https://elections.bc.ca" target="_blank">
                <ListItemButton>
                    <ListItemIcon>
                        <LinkIcon />
                    </ListItemIcon>
                    <ListItemText primary='elections.bc.ca' />
                </ListItemButton>                
            </ListItem>
        </List>

      </Box>
    )
    break;
  }
}

const themedStyles = (theme: Theme) => {
  return {
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#db282e",
    },
    infoArea: {
      backgroundColor: "#e9e8dc",
      fontFamily: 'Bebas Neue',
    },    
    bottomNavigation: {
      backgroundColor: "#e9e8dc",
      // backgroundColor: "#565656",
      fontFamily: 'Bebas Neue',
      // color: 'white'
    },
    topDivider: {
      backgroundColor: "#db282e",
      width: '100%', 
      height: '.1rem',      
    },
    bottomDivider: {
      backgroundColor: "#565656",
      width: '100%', 
      height: '.2rem',
    },
    switch_track: {
      backgroundColor: "#f50057",
    },
    switch_base: {
      color: "#f50057",
      "&.Mui-disabled": {
          color: "#e886a9"
      },
      "&.Mui-checked": {
          color: "#95cc97"
      },
      "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#4CAF50",
      }
    },
    switch_primary: {
      "&.Mui-checked": {
          color: "#4CAF50",
      },
      "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#4CAF50",
      },
    },
  }
}



// function toggleLayerVisibility(id: string, visible: boolean) {

//   useLayerStore.setState((prev) => ({
//     layerVisibility: new Map(prev.layerVisibility).set(id,visible),
//   }));
//   useLayerStore.setState(() => ({bs11Visible: visible}));
// }


export default function App() {
  const isInitialMount = useRef(true);
  const [largeScreen, setLargeScreen] = React.useState(false);
  const { region, setRegion, bs11, bs10, toggle, map, overlay, toggleBs11, toggleBs10, bs11Overlay, bs10Overlay, positionOverlay, selected, setSelected, searchPlace, setSearchPlace, ed, edInfo, setEdInfo, setHighlightInfo, defaultPlace, setDefaultPlace } = useLayerVisibilityStore();
  const [open, setOpen] = React.useState(false);
  const [showInfoArea, setShowInfoArea] = React.useState(false);
  const [containerHeight, setContainerHeight] = React.useState(baseHeaderHeight + baseSearchHeight + (edInfo ? baseInfoHeight : 0) + baseFooterHeight);
  const [edLabel, setEdLabel] = React.useState<any>();
  const [drawerContentType, setDrawerContentType] = React.useState(DrawerContentType.Menu);
  const deckGlMap = useMap();

  const handleMenuDrawerOpen = () => {
    setDrawerContentType(DrawerContentType.Menu);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleHelpDrawerOpen = () => {
    setDrawerContentType(DrawerContentType.Help);
    setOpen(true);
  };

  // const theme = useTheme();
  // const theme = responsiveFontSizes(useTheme());
  const theme = responsiveFontSizes(createTheme());

  const infoContainerHeight = () => {
    return (largeScreen ? baseInfoHeight : baseInfoHeightSm);
  }

  const infoContainerMargin = () => {
    return (largeScreen ? 0 : 0);
  }

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setLargeScreen(window.innerWidth > theme.breakpoints.values.sm);
      // if (window.innerWidth>700) {
      //    setLargeScreen(true);
      // }
    }    
    // Add event listener
    window.addEventListener("resize", handleResize);
    handleResize();

    if (isInitialMount.current) {
        // @ ts-expect-error: Let's ignore a compile error like this unreachable code 
        //isInitialMount.current.scrollIntoView();
        window.scrollTo(1,0);
      isInitialMount.current = false;

      const vals = ['bnwtc-map', 'fvlmr-map', 'ns-map', 'nvi-map', 'pgc-map', 'rd-map', 'svi-map', 'su-map', 'in-map', 'kt-map', 'no-map', 'va-map', 'region1map','region2map','region3map','region4map','region5map','region6map','region7map','region8map','region9map','region10map','region11map','region12map'];

      const queryParameters = new URLSearchParams(window.location.search);
      console.log(window.location.pathname); // pathname will be /region5map

      if (vals.includes(window.location.pathname.replace("/","").toLocaleLowerCase())) {
        const region = window.location.pathname.replace("/","").toLowerCase();
        setRegion(region);
        // const bnd = bs11RegionBounds(region);
        // map?.fitBounds(bnd);
      } else if (queryParameters && queryParameters.has("region")) {
        console.log("> queryParameters:",queryParameters);
        const region = queryParameters.get("region");
        setRegion(region!.toLowerCase());
        // const bnd = bs11RegionBounds(region!);
        // map?.fitBounds(bnd);
      } else if (queryParameters && queryParameters.has("ed") && queryParameters.has("x") && queryParameters.has("y") && queryParameters.has("address")) {
        const ed = queryParameters.get("ed");
        const x = queryParameters.get("x");
        const y = queryParameters.get("y");
        const address = queryParameters.get("address");
        console.log("> ED: ", ed, x, y, address, ed=='null', typeof(parseFloat(x!)), parseFloat(x!));
        if (ed && ed !== null && address && address !== null && !Number.isNaN(parseFloat(x!)) && !Number.isNaN(parseFloat(y!)) ) {
            const info = bs11EdInfo(ed.toLowerCase());
            const p:PlaceType = {
                description: address,
                result: {ed_abbr:info.edAbbr, ed_name: info.edName, x: Number(x), y: Number(y)}, 
                structured_formatting: {
                    main_text: address,
                    secondary_text: `${info.edName} (${info.edAbbr})`,
                }
            };            
            setDefaultPlace(p);
            setSearchPlace(p);
            setHighlightInfo(null);
            setEdInfo(info);

            window.history.pushState(null,"",window.location.href.split("?")[0]);

            console.log("> Place",p);
        }

      }

      // const queryParameters = new URLSearchParams(window.location.search);
      // console.log(window.location.pathname); // pathname will be /region5map

      // if (vals.includes(window.location.pathname.replace("/","").toLocaleLowerCase())) {
      //   const region = window.location.pathname.replace("/","").toLowerCase();
      //   setRegion(region);
      //   const bnd = getRegionBounds(region);
      //   map?.fitBounds(bnd);
      // } else if (queryParameters && queryParameters.has("region")) {
      //   const region = queryParameters.get("region");
      //   setRegion(region!.toLowerCase());
      //   const bnd = getRegionBounds(region!);
      //   map?.fitBounds(bnd);
      // }
      // const region = queryParameters.get('region')?.toString();
      // setRegion(region ? region : "");
    } else {

    }
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);    
  });

  return (
    // <ThemeProvider theme={ AppBarTheme }>
    <ThemeProvider theme={ theme }>
    <div >
    <AppBar position="fixed" sx={{ ...themedStyles(theme).appBar, zIndex: 1 }}>
        <Toolbar sx={{ ...themedStyles(theme).infoArea }}>
          <LogoImage />
          <Typography variant="h4" style={{ marginLeft: 'auto', fontFamily: 'Bebas Neue', color: 'black' }}>My District 2024</Typography>
          <IconButton size="medium" edge="end" aria-label="help" sx={{ color: 'black' }} onClick={handleHelpDrawerOpen}>
            <HelpOutline />
          </IconButton>
        </Toolbar>

        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleMenuDrawerOpen}>
            <MenuIcon />
          </IconButton>

          <AzureAutocomplete defaultPlace={searchPlace} />

        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '100%',
            boxSizing: 'border-box',
            height: '100%',
            [theme.breakpoints.up('sm')]: {
              width: drawerWidth,
            },
          },
        }}
        variant="temporary"
        anchor={ drawerContentType === DrawerContentType.Menu ? "left" : "right" }
        open={ open }
      >
        <Box>
          <Stack direction="row" alignItems="center" justifyContent={'space-between'} sx={{pl: 2}}>
            <Typography variant="h4" sx={{fontFamily:'Bebas Neue'}}>{drawerContentType === DrawerContentType.Menu ? 'My District 2024' : 'How to find your electoral district'}</Typography>
            <IconButton size="large" color="inherit" aria-label="menu" sx={{mr:'2px'}} onClick={handleDrawerClose}>
              {/* {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
              { drawerContentType === DrawerContentType.Menu ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
            </IconButton>
          </Stack>
          <Divider />
        </Box>
        {renderDrawerContent(drawerContentType)}
      </Drawer>

      <Toolbar id='spacer1' />
      <Toolbar id='spacer2' />
      {/* <GoogleMapsWrapper >
        <Layout>
          <GoogleMaps mapId="map_id" locations={LOCATIONS} 
            containerHeight={baseHeaderHeight + baseSearchHeight + (edInfo ? infoContainerHeight() : 0) + baseFooterHeight - (largeScreen ? 0 : 16)} />
        </Layout>
        <Notifier />
      </GoogleMapsWrapper> */}

      <GoogleMapsContainer containerHeight={baseHeaderHeight + baseSearchHeight + (edInfo ? infoContainerHeight() : 0) + baseFooterHeight - (largeScreen ? 0 : 16)} />

      { edInfo &&
        <NotificationArea containerHeight={infoContainerHeight()} margin={infoContainerMargin()} />
      }
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}
            sx={{height: `${baseFooterHeight}px`}} 
            divider={<Divider orientation="vertical" sx={{height: "75%"}}/>}>
          {/* <InfoDrawer /> */}
          <FormControlLabel 
                control={<Android12Switch id="bs11" defaultChecked color="error" onChange={toggleBs11} />} // was onLayerChange
                label={<Typography variant="body1">2024</Typography>} labelPlacement="end" color="error" 
                sx={{ fontFamily: 'Bebas Neue', fontWeight: 'Bold' }}
              />
          <Typography>BOUNDARIES</Typography>
          <FormControlLabel 
                control={<Android12Switch id="bs10" color="default" onChange={toggleBs10} />} 
                label={<Typography variant="body1">2020</Typography>} labelPlacement="end" 
                sx={{ fontFamily: 'Bebas Neue' }} 
              />
        </Stack>
    </div>
  </ThemeProvider>
  );
}