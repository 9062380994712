import {useMap} from '@vis.gl/react-google-maps';
import {useEffect, useMemo} from 'react';

import {GoogleMapsOverlay} from '@deck.gl/google-maps/typed';

import type {LayersList} from '@deck.gl/core/typed';
import { useLayerVisibilityStore } from "../../store/layerStore";
import { IconLayer, MVTLayer, PickingInfo } from 'deck.gl/typed';
import { bs10EdBounds, bs10EdInfo, bs11EdBounds, bs11EdInfo } from "./utils/utils";
import { truncate } from 'fs';

export type DeckglOverlayProps = {layers?: LayersList};

/**
 * A very simple implementation of a component that renders a list of deck.gl layers
 * via the GoogleMapsOverlay into the <Map> component containing it.
 */
// export const DeckGlOverlay = ({layers}: DeckglOverlayProps) => {
export const Bs11DeckGlOverlay = () => {
    const { bs11, bs10, toggle, toggleBs11, toggleBs10, bs10Overlay, positionOverlay, selected, setSelected, searchPlace, setSearchPlace, ed, edInfo, markerLat, markerLng, setMarkerLat, setMarkerLng, setShowInfoWindow, highlightInfo, setHighlightInfo } = useLayerVisibilityStore();
    // The GoogleMapsOverlay can persist throughout the lifetime of the DeckGlOverlay
    const overlay = useMemo(() => bs11GoogleMapsOverlay(),[]);
  
    const layerClick = (info:PickingInfo, event:any) => {
        setShowInfoWindow(false);
        setSelected(info);
        const o = bs11EdInfo(info.object.properties.edabbr);
        // const bbox: turf.helpers.BBox = turf.bbox(info.object.geometry);
        const b = bs11EdBounds(info.object.properties.edabbr);
    
        setHighlightInfo({...o, bs:'11'});

        setMarkerLat(event.srcEvent.latLng.lat());
        setMarkerLng(event.srcEvent.latLng.lng());
        setShowInfoWindow(true);
        map?.fitBounds(b);
    }  

    const lyr = bs11MVTLayer(
        'bs11', 
        'https://ebcwtvgeoserver.canadacentral.cloudapp.azure.com/geoserver/gwc/service/tms/1.0.0/wtv:EBC_ELECTORAL_DISTS_BS11@EPSG:900913@pbf/{z}/{x}/{-y}.pbf', 
        bs11, 
        selected ? selected.object.properties.edabbr : "",
        highlightInfo,
        edInfo,
        setSelected
    );
    const layers = [lyr];

    const map = useMap();
    useEffect(() => {
        overlay.setMap(map);
    }, [map]);

    // Whenever the rendered data changes, the layers will be updated
    useEffect(() => {
        const lyrUpdates = bs11MVTLayer(
            'bs11', 
            'https://ebcwtvgeoserver.canadacentral.cloudapp.azure.com/geoserver/gwc/service/tms/1.0.0/wtv:EBC_ELECTORAL_DISTS_BS11@EPSG:900913@pbf/{z}/{x}/{-y}.pbf', 
            bs11, 
            edInfo ? edInfo.edAbbr : "",
            highlightInfo,
            edInfo,
            layerClick
        );
        overlay.setProps({layers: [lyrUpdates],});
    }, [layers]); //was [layers]

    // No dom rendered by this component
    return null;
};

export const Bs10DeckGlOverlay = () => {
    const { bs11, bs10, toggle, toggleBs11, toggleBs10, bs10Overlay, positionOverlay, selected, setSelected, searchPlace, setSearchPlace, ed, edInfo, markerLat, markerLng, setMarkerLat, setMarkerLng, setShowInfoWindow, highlightInfo, setHighlightInfo } = useLayerVisibilityStore();
    // The GoogleMapsOverlay can persist throughout the lifetime of the DeckGlOverlay
    const overlay = useMemo(() => bs10GoogleMapsOverlay(bs11),[]);
  
    const layerClick = (info:PickingInfo, event:any) => {
        setShowInfoWindow(false);
        setSelected(info);
        const o = bs10EdInfo(info.object.properties.edabbr);
        // const bbox: turf.helpers.BBox = turf.bbox(info.object.geometry);
        const b = bs10EdBounds(info.object.properties.edabbr);
    
        setHighlightInfo({...o, bs:'10'});

        setMarkerLat(event.srcEvent.latLng.lat());
        setMarkerLng(event.srcEvent.latLng.lng());
        setShowInfoWindow(true);
        map?.fitBounds(b);
    }  

    const lyr = bs10MVTLayer(
        'bs10', 
        'https://ebcwtvgeoserver.canadacentral.cloudapp.azure.com/geoserver/gwc/service/tms/1.0.0/wtv:EBC_ELECTORAL_DISTS_BS10@EPSG:900913@pbf/{z}/{x}/{-y}.pbf', 
        bs10, 
        selected ? selected.object.properties.edabbr : "",
        highlightInfo,
        bs11,   
        () => {},
    );
    const layers = [lyr];

    const map = useMap();
    useEffect(() => {
        overlay.setMap(map);
    }, [map]);

    // Whenever the rendered data changes, the layers will be updated
    useEffect(() => {
        const lyrUpdates = bs10MVTLayer(
            'bs10', 
            'https://ebcwtvgeoserver.canadacentral.cloudapp.azure.com/geoserver/gwc/service/tms/1.0.0/wtv:EBC_ELECTORAL_DISTS_BS10@EPSG:900913@pbf/{z}/{x}/{-y}.pbf', 
            bs10, 
            edInfo ? edInfo.edAbbr : "",
            highlightInfo,
            bs11,
            bs11 ? () => {} : layerClick,
        );
        overlay.setProps({layers: [lyrUpdates],});
    }, [layers]); //was [layers]

    // No dom rendered by this component
    return null;
};

const bs11GoogleMapsOverlay = () => {
    const o = new GoogleMapsOverlay({
        getTooltip: ({object}) => object && {
            // text:  `${object.properties.edname} (${object.properties.edabbr})`,
            html: `<div><b>${object.properties.edname} (${object.properties.edabbr})</b></div>`,
            // html: `<h3>${object.properties.edname} (${object.properties.edabbr})</h3>`,
            style: {
                display: 'inline',
                padding: '3px',
                backgroundColor: '#e8eaeda5', //'#e9e8dca5'
                borderRadius: '3px',
                // border: '1px solid #e9e8dcf1',
                fontSize: '0.75rem',
                // fontFamily: 'Bebas Neue',
                fontWeight: '400',
                color: '#db282e',
                width: 'auto',
                top: '-15px',
                left: '15px',
                whiteSpace: 'nowrap',
                boxShadow: '1px 1px 6px #565656a5',
            }
        },
        layers: [],
    });
    return o;
}

const bs10GoogleMapsOverlay = (isOffsetTooltip: boolean) => {
    const o = new GoogleMapsOverlay({
        getTooltip: ({object}) => object && {
            html: `<b>${object.properties.edname} (${object.properties.edabbr})</b>`,
            style: {
                display: 'inline',
                padding: '3px',
                backgroundColor: '#e8eaeda5', //'#e9e8dca5',e8eaed
                borderRadius: '3px',
                // border: '1px solid #e9e8dcf1',
                fontSize: '0.75rem',
                fontWeight: '400',
                color: '#565656',
                width: 'auto',
                top: '15px', //23
                left: '15px',
                whiteSpace: 'nowrap',
                boxShadow: '1px 1px 6px #565656a5',
            }
        },
        layers: [],
    });
    return o;
}

const bs11MVTLayer = (layerId: string, dataUrl: string, defaultVisibile: boolean, selected: string, highlightInfo: any, edInfo: any, callback: any) => {
  return new MVTLayer({
    id: layerId,
    data: dataUrl,
    minZoom: 2,
    maxZoom: 20,
    binary: true,
    stroked: true,    
    getFillColor: (f: any) => {
    //   if(f.properties?.edabbr == selected) { return [219,40,46,25];} else return [51, 51, 51, 0];
        const baseColor = [56, 56, 56, 0];
        const highlightColor =  [219,40,46,40];
        const lowlightColor = [56, 56, 56, 50];
        if (f.properties && ((highlightInfo && highlightInfo.bs ==="11") || edInfo)) {
            // const isSearchMatch = (edInfo && f.properties.edabbr == edInfo.edAbbr);
            // const isClickMatch = (highlightInfo && f.properties.edabbr == highlightInfo.edAbbr);
            const isMatch = (edInfo && f.properties.edabbr == edInfo.edAbbr) || (highlightInfo && highlightInfo.bs === "11" && f.properties.edabbr == highlightInfo.edAbbr);
            return isMatch ? baseColor : lowlightColor;
            // return f.properties.edabbr == highlightInfo.edAbbr ? baseColor : lowlightColor;
        } else {
            return baseColor;
        }
    },
    getLineColor: [219, 40, 46], //[46, 125, 50], //[219, 40, 46],//[37, 255, 37],
    // getLineColor: (f: any) => { if(f.properties?.edabbr == selected) { return [37, 255, 37];} else return [219, 40, 46]; },
    autoHighlight: false,
    highlightColor: [219,40,46,25],
    lineWidthUnits: 'pixels',
    // getLineWidth: 2,
    getLineWidth: (f: any) => { 
        const isMatch = (edInfo && f.properties.edabbr == edInfo.edAbbr);
        return isMatch ? 2.3 : 2.3; 
    },
    lineWidthMinPixels: 0,
    pickable: true,
    onClick: callback,
    visible: defaultVisibile,
    onDataLoad: (data:any) => {
      console.log(data);
    },
    onError: (err) => {
      console.log("GeoJSON Layer Error",err);
    },
    // onHover: (data) => console.log(data),
    updateTriggers: {
       getFillColor: [highlightInfo, edInfo],
       getLineWidth: [selected],
       getLineColor: [selected]
     }
  })
}

const bs10MVTLayer = (layerId: string, dataUrl: string, defaultVisibile: boolean, selected: any, highlightInfo: any, isBs11Visible: boolean, callback: any) => {
    //'https://ebcwtvgeoserver.canadacentral.cloudapp.azure.com/geoserver/gwc/service/tms/1.0.0/wtv:EBC_ELECTORAL_DISTS_BS10@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'
    return new MVTLayer({
      id: layerId,
      data: dataUrl,
      minZoom: 2,
      maxZoom: 18,
      binary: true,
      onTileError: error => {
        if (error.message.includes('404')) {
          // trying to load tiles in the previous viewport, ignore
          console.log('404');
        } else {
          console.log(error.message);
        }
      },
      stroked: true,    
        getFillColor: (f: any) => {
        //   if(f.properties?.edabbr == selected) { return [219,40,46,25];} else return [51, 51, 51, 0];
            const baseColor = [56, 56, 56, 0];
            const lowlightColor = [56, 56, 56, 50];
            if (f.properties && highlightInfo && !isBs11Visible && false) {
                const isMatch = (highlightInfo && f.properties.edabbr == highlightInfo.edAbbr);
                return isMatch ? baseColor : lowlightColor;
            } else {
                return baseColor;
            }
        },
      getLineColor: [112, 111, 106], // [86, 86, 86],//[37, 255, 37],
      autoHighlight: false,
      highlightColor: [1,1,1,50],
      lineWidthUnits: 'pixels',
      getLineWidth: 1.3, //10,
      lineWidthMinPixels: 1,
      pickable: true,
      onClick: callback,
      visible: defaultVisibile,
      updateTriggers: {
        getFillColor: [34]
      },
      // onHover: (data) => console.log(data),
  
    })
  }