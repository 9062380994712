import {useMap, Marker, MarkerRef} from '@vis.gl/react-google-maps';
import {useEffect, useMemo} from 'react';

import type {LayersList} from '@deck.gl/core/typed';
import { useLayerVisibilityStore } from "../../store/layerStore";
import { bs11EdBounds, bs10EdBounds, bs11RegionBounds } from "./utils/utils";

export type DeckglOverlayProps = {layers?: LayersList};

export const SearchListener = () => {
    const { edInfo } = useLayerVisibilityStore();
    const map = useMap();
    useEffect(() => {
        if (edInfo && map) {
            const bounds = bs11EdBounds(edInfo?.edAbbr);
            map?.fitBounds(bounds);
        }
    }, [edInfo]);

    return null;
}

export const RegionListener = () => {
    const { region } = useLayerVisibilityStore();
    const map = useMap();
    useEffect(() => {
        console.log("> RegionListener",region,map);

        if (region && map) {
            const bnd = bs11RegionBounds(region);
            map?.fitBounds(bnd);
        }
    }, [region]);

    return null;
}

