import {useMap, Marker} from '@vis.gl/react-google-maps';
import {useEffect, useMemo} from 'react';
import type {LayersList} from '@deck.gl/core/typed';
import { useLayerVisibilityStore } from "../../store/layerStore";
import { bs11EdBounds, bs10EdBounds, bs11RegionBounds } from "./utils/utils";
import { Console } from 'console';

export default function MapMarker() {

    const { marker } = useLayerVisibilityStore();
    const map = useMap();
    const foo = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>tooltip-account</title><path d="M20,2H4A2,2 0 0,0 2,4V16A2,2 0 0,0 4,18H8L12,22L16,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M12,4.3C13.5,4.3 14.7,5.5 14.7,7C14.7,8.5 13.5,9.7 12,9.7C10.5,9.7 9.3,8.5 9.3,7C9.3,5.5 10.5,4.3 12,4.3M18,15H6V14.1C6,12.1 10,11 12,11C14,11 18,12.1 18,14.1V15Z" /></svg>
    const foo2 = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>map-marker-account</title><path d="M12 2C8.14 2 5 5.14 5 9C5 14.25 12 22 12 22S19 14.25 19 9C19 5.14 15.86 2 12 2M12 4C13.1 4 14 4.9 14 6C14 7.11 13.1 8 12 8S10 7.11 10 6C10 4.9 10.9 4 12 4M12 14C10.33 14 8.86 13.15 8 11.85C8 10.53 10.67 9.8 12 9.8S16 10.53 16 11.85C15.14 13.15 13.67 14 12 14Z" /></svg>
    const mdiCircleSmall = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>circle-small</title><path d="M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z" /></svg>;
    const svgMarker = {
        //path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        // path: "M20,2H4A2,2 0 0,0 2,4V16A2,2 0 0,0 4,18H8L12,22L16,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M12,4.3C13.5,4.3 14.7,5.5 14.7,7C14.7,8.5 13.5,9.7 12,9.7C10.5,9.7 9.3,8.5 9.3,7C9.3,5.5 10.5,4.3 12,4.3M18,15H6V14.1C6,12.1 10,11 12,11C14,11 18,12.1 18,14.1V15Z",
        path: "M12 2C8.14 2 5 5.14 5 9C5 14.25 12 22 12 22S19 14.25 19 9C19 5.14 15.86 2 12 2M12 4C13.1 4 14 4.9 14 6C14 7.11 13.1 8 12 8S10 7.11 10 6C10 4.9 10.9 4 12 4M12 14C10.33 14 8.86 13.15 8 11.85C8 10.53 10.67 9.8 12 9.8S16 10.53 16 11.85C15.14 13.15 13.67 14 12 14Z",
        // path: "M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z",
        fillColor: "#db282e",
        fillOpacity: 0.99,
        strokeWeight: 1,
        strokeColor: "#a71e23",//"#a71e23",
        rotation: 0,
        scale: 1.5,
        anchor: new google.maps.Point(4.5, 12.5),
      };
      const svgPointMarker = {
        path: "M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z",
        fillColor: "#black",
        fillOpacity: 0.99,
        rotation: 0,
        scale: 1,
        anchor: new google.maps.Point(0, 0),
      };

    useEffect(() => {
        console.log("> MapMarker",marker,map);
        if (map) {
            console.log("> MapMarker with map");
            console.log(marker);
        } else {
            console.log("> MapMarker NO map");
        }
        if (marker && map) {
            console.log(" > ")
        }
    }, [marker]);

    return (
        <>
            { marker &&
                <Marker position={marker} icon={svgMarker} />
            }
        </>
    )
}